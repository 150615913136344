<app-breadcrumb name="Kontakt"></app-breadcrumb>

<section class="constructo-features-area section-padding">
    <div class="container">
        <div class="row" style="margin-bottom: 32px;">
            <div class="col-xs-12 text-center">
                <div class="section-title">
                    <h2>Dane kontaktowe</h2>
                    <div class="line">
                        <img src="assets/img/line.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-map-marker fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-map-marker fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>Adres</h4>
                    <p>Smulsko 14, 62-731 Przykona</p>
                    <p>Wielkopolska</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-phone fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-phone fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>Numer telefonu</h4>
                    <p>+48 601 931 754</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-at fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-at fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>E-MAIL</h4>
                    <p>raf545@vp.pl</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-calendar fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-calendar fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>Godziny pracy</h4>
                    <p>Pon - pt : 8:00 - 17:00</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="constructo-features-area">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="section-title">
                    <h2>Dane administracyjne</h2>
                    <div class="line">
                        <img src="assets/img/line.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
        <div class="row padding-top">
            <div class="col-sm-12 col-md-6 col-md-offset-3">
                <div class="form-group">
                    <small class="text-muted">Nazwa firmy</small>
                    <div>"BARTEZ" Rafał Mintus</div>
                </div>
                <div class="form-group">
                    <small class="text-muted">Właściciel firmy</small>
                    <div>Rafał Mintus</div>
                </div>
                <div class="form-group">
                    <small class="text-muted">Adres</small>
                    <div>62-731 Przykona, Smulsko 14,  Wielkopolskie</div>
                </div>
                <div class="form-group">
                    <small class="text-muted">NIP</small>
                    <div>6681870939</div>
                </div>
                <div class="form-group">
                    <small class="text-muted">Regon</small>
                    <div>300658523</div>
                </div>
                <div class="form-group">
                    <small class="text-muted">CEIDG</small>
                    <div>
                        <a href="assets/files/contact/CEIDG_BARTEZ_RafalMintus_2021-02-09.pdf" target="_blank">Wpis z dn 2021-02-09</a>
                        <br>
                        <small>Najnowszy pod adresem <a href="http://prod.ceidg.gov.pl/" target="_blank">CEIDG</a></small>
                    </div> 
                </div>
            </div>
        </div>
        <br>
    </div>
</section>

<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="section-title">
                    <h2>Jak dojechać</h2>
                    <div class="line">
                        <img src="assets/img/line.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
        <div class="row padding-top">
            <div class="col-sm-12">
                <div class="text-center" style="margin-bottom: 12px;">
                    <h4>
                        <a target="_blank" href="https://www.google.com/maps/dir//Smulsko+14,+62-731+Smulsko/@51.9932994,17.6181408,9z?hl=pl-PL">Pokaż trasę</a>
                    </h4>
                </div>
                <a  target="_blank" href="https://www.google.com/maps/dir//Smulsko+14,+62-731+Smulsko/@51.7870075,18.781201,10z?hl=pl-PL"><img src="assets/img/map.png" /></a>
            </div>
        </div>
    </div>
</section>