<app-breadcrumb name="Usługi"></app-breadcrumb>
<app-subheader></app-subheader>

<app-features-main></app-features-main>

<section class="constructo-serivces-section section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="section-title">
                    <h2>Czym się zajmujemy</h2>
                    <p>Usługi budowlane w których się specjalizujemy.</p>
                    <div class="line">
                        <img src="assets/img/line.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
        <div class="row padding-top">
            <div class="col-sm-4 text-left">
                <div class="constructo-single-services">
                    <img src="assets/img/services/Remonty.jpg" alt="firma-bartez.pl">
                    <div class="services-content">
                        <h3>Remonty, Adaptacje</h3>
                        <p>Wykonujemy wszelkiego rodzaju renowacje, przebudowy, remonty, adaptacje zarówno w starym jak i nowym budownictwie. </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-left">
                <div class="constructo-single-services">
                    <img src="assets/img/services/WykonczeniaWnetrz.jpg" alt="firma-bartez.pl">
                    <div class="services-content">
                        <h3>Wykończenia wnętrz</h3>
                        <p>Usługi tynkowania, gładzi, zabudowy płyt KG (karton gips), wylewki, podłogi, malowanie, kafelkowanie, docieplenia i wiele innych.  </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-left">
                <div class="constructo-single-services">
                    <img src="assets/img/services/servic-template.jpg" alt="firma-bartez.pl">
                    <div class="services-content">
                        <h3>Kompleksowa budowa domów</h3>
                        <p> Budowa budynków mieszkalnych i gospodarczych od projektu po wykończenie. 
                            Wykonanie budynku w stanie surowym, deweloperskim lub pod klucz.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-left">
                <div class="constructo-single-services">
                    <img src="assets/img/services/servic-template.jpg" alt="firma-bartez.pl">
                    <div class="services-content">
                        <h3>Roboty ziemne</h3>
                        <p>Przygotowanie terenu pod inwestycje. Przygotowanie wstępnej analizy gruntu. Wykopy pod fundamenty budynku oraz doprowadzenie przyłądzy według projektu działki.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-left">
                <div class="constructo-single-services">
                    <img src="assets/img/services/GeneralneWykonawstwo.jpg" alt="firma-bartez.pl">
                    <div class="services-content">
                        <h3>Generalne wykonawstwo</h3>
                        <p>Wykonywanie robót budowlanych pod klucz z uwzględnieniem wszystkich etapów budowy, od projektu po wykończenie. 
                            Usługa skierowana głównie do podmiotów użyteczności publicznej. </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 text-left">
                <div class="constructo-single-services">
                    <img src="assets/img/services/PraceRozbiorkowe.jpg" alt="firma-bartez.pl">
                    <div class="services-content">
                        <h3>Prace rozbiórkowe</h3>
                        <p>Całościowe lub częściowe rozbiórki budynków mieszkalnych, gospodarczych i przemysłowych. 
                            Usługa demontażu, segregacji, utylizacji i wywozu gruzu powyburzeniowego</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="constructo-count-down section-padding">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 text-center text-uppercase">
                <div class="single-countdown">
                    <div class="count-icon">
                        <i class="fa fa-check fa-2x feature-icon"></i>
                    </div>
                    <h2 class="counter">22</h2>
                    <h4>TODO: Liczba zamówień</h4>
                </div>
            </div>
            <div class="col-sm-4 text-center text-uppercase">
                <div class="single-countdown">
                    <div class="count-icon">
                        <i class="fa fa-file fa-2x feature-icon"></i>
                    </div>
                    <h2 class="counter">1400000</h2>
                    <h4>TODO: Kwota wykonanych zamówień</h4>
                </div>
            </div>
            <div class="col-sm-4 text-center text-uppercase">
                <div class="single-countdown">
                    <div class="count-icon">
                        <i class="fa fa-users fa-2x feature-icon"></i>
                    </div>
                    <h2 class="counter">6</h2>
                    <h4>TODO: Pracownicy</h4>
                </div>
            </div>
        </div>
    </div>
</section>